import 'core-js/features/array/find-index';
import 'core-js/features/array/find';
import 'core-js/features/array/from';
import 'core-js/features/array/includes';
import 'core-js/features/map';
import 'core-js/features/math/log10';
import 'core-js/features/number/is-nan';
import 'core-js/features/object/assign';
import 'core-js/features/object/entries';
import 'core-js/features/object/keys';
import 'core-js/features/promise';
import 'core-js/features/set';
import 'core-js/features/string/includes';
import 'core-js/features/string/starts-with';
import 'core-js/features/weak-map';
import 'custom-event-polyfill';
import 'formdata-polyfill';
import 'intersection-observer';
import 'mutation-observer-inner-html-shim';
import 'whatwg-fetch';
import elementClosest from 'element-closest';

elementClosest(window);
